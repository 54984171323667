var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataLoaded)?_c('section',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}}),_vm._v(" Dashboard ")],1),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'view-mycompany' }}},[_vm._v(" "+_vm._s(_vm.resourceName)+" ")]),_c('b-breadcrumb-item',{attrs:{"active":true}},[_vm._v(" Edit ")])],1)],1)]),_c('b-col',{staticClass:"mb-2 text-right",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'view-mycompany' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"EyeIcon","size":"16"}}),_vm._v(" View "+_vm._s(_vm.resourceName)+" ")],1)],1)],1),_c('b-card',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"pl-1 border-left-primary border-left-3"},[_vm._v(" Invite User ")]),_c('validation-observer',{ref:"inviteForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendCompanyInvite.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"full_name"}},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required","vid":"full_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full_name","name":"full_name","state":errors.length > 0 ? false:null,"placeholder":"Full Name"},model:{value:(_vm.inviteFullName),callback:function ($$v) {_vm.inviteFullName=$$v},expression:"inviteFullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"inviteEmail"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"recipient_email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"inviteEmail","name":"email","state":errors.length > 0 ? false:null,"placeholder":"User Email"},model:{value:(_vm.inviteEmail),callback:function ($$v) {_vm.inviteEmail=$$v},expression:"inviteEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || !dirty}},[_vm._v(" Send Invite ")])],1)],1)],1)]}}],null,false,1520784408)})],1),_c('b-card',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"pl-1 border-left-primary border-left-3"},[_vm._v(" Edit "+_vm._s(_vm.resourceName)+" ")]),_c('validation-observer',{ref:"resourceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateCompany.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false:null,"placeholder":"company name"},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || !dirty}},[_vm._v(" Update ")])],1)],1)],1)]}}],null,false,2522137692)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }